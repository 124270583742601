<template>
  <div class="information">
    <div class="main_box">
      <p class="title">基本资料</p>
      <div class="top_form">
        <el-form :inline="true" :model="formInline" label-width="90px" class="demo-form-inline">
          <el-form-item label="学校：">
            <el-input v-model="formInline.school" disabled placeholder="审批人"></el-input>
          </el-form-item>
          <el-form-item label="用户名：">
            <el-input v-model="formInline.userName" disabled placeholder="审批人"></el-input>
          </el-form-item>
          <el-form-item label="年级：">
            <el-input v-model="formInline.grade" disabled placeholder="审批人"></el-input>
          </el-form-item>
          <el-form-item label="班级：">
            <el-input v-model="formInline.class" disabled placeholder="审批人"></el-input>
          </el-form-item>
          <el-form-item label="性 别：">
            <el-radio-group v-model="radio" disabled>
              <!-- 未选中的样式  defaultradio  -->
              <el-radio :class="radio != 1 ? 'defaultradio' : ''"  :label="2">女</el-radio>
              <el-radio :class="radio != 2 ? 'defaultradio' : ''"  :label="1">男</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="Personalization">
        <p class="title">个性化设置</p>
        <div class="btm_form">
          <el-form :inline="true" label-width="110px" class="demo-form-inline">
            <el-form-item label="护眼模式：">
              <el-switch @change="eyeChange(formInline.eye)" v-model="formInline.eye" active-color="#5990FF" inactive-color="#CCCCCC" :active-text="showName(formInline.eye,1)">
              </el-switch>
            </el-form-item>
            <el-form-item label="发音模式：">
              <el-switch @change="fyChange(formInline.sound)" v-model="formInline.sound" active-color="#5990FF" inactive-color="#F93A00" :active-text="showName(formInline.sound,2)">
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectClassByYhid, myPersonalConfig, addPersonalConfig, updatePersonalConfig } from "@/api/student";
// 事件总线
import bus from '@/utils/bus.js'
export default {
  data() {
    return {
      formInline: {
        school: "阿萨",
        grade: "",
        userName: "",
        class: "",
        eye: false,
        sound: "",
      },
      radio: 1,
      isSetUp: false, // 是否设置过
      uid: ''
    };
  },
  methods: {
    // 查询个性化设置
    myPersonalConfigFun() {
      myPersonalConfig({xsid: this.$store.state.userInfo.yhid}).then(res => {
        if(!res.result) {
          this.isSetUp = true
          return
        }
        localStorage.setItem(`sound_${this.$store.state.userInfo.realname}`, res.result.fyms ? 'm' : 'y' )
        this.uid = res.result.id
        this.formInline.eye = res.result.hyms
        this.formInline.sound = res.result.fyms
      })
    },
    // 新增个性化设置
    addPersonalConfigFun(){
      addPersonalConfig({
        cjr: this.$store.state.userInfo.yhid,
        xgr: this.$store.state.userInfo.yhid,
        xsid: this.$store.state.userInfo.yhid,
        fyms: this.formInline.sound - 0,
        hyms: this.formInline.eye - 0
      }).then(res => {
        this.myPersonalConfigFun()
      }).catch(() => {
        this.$message.warning('基本资料修改失败，请刷新网页或联系管理员')
      })
    },
    // 修改个性化设置
    updatePersonalConfigFun(){
      updatePersonalConfig({
        fyms: this.formInline.sound - 0,
        hyms: this.formInline.eye - 0,
        xsid: this.$store.state.userInfo.yhid,
        id: this.uid
      }).then(res => {
        this.myPersonalConfigFun()
      }).catch(() => {
        this.$message.warning('基本资料修改失败，请刷新网页或联系管理员')
      })
    },
    eyeChange(val) {
      if(this.isSetUp){
        this.addPersonalConfigFun()
      }
      this.updatePersonalConfigFun()
      sessionStorage.setItem('eye', val)
      bus.emit("eye", val);
    },
    fyChange(val) {
      if(this.isSetUp){
        this.addPersonalConfigFun()
      }
      this.updatePersonalConfigFun()
      this.$message.success(!val ? '英式发音切换成功' : '美式发音切换成功')
    },
    // switch显示文字
    showName(val, type) {
      if (type == 1) {
        return val ? "开启" : "关闭";
      } else {
        return val ? "美式" : "英式";
      }
    },
  },
  mounted() {
    this.myPersonalConfigFun()
    selectClassByYhid({ yhid: this.$store.state.userInfo.yhid }).then((res) => {
      if(res.result.length>0){
        this.formInline.grade = res.result[0].njmc
        this.formInline.class = res.result[0].bjmc
      }

      // this.$set(this.formInline, "grade", res.result[0].njmc);
      // this.$set(this.formInline, "class", res.result[0].bjmc);
    });
    this.formInline.school = JSON.parse(sessionStorage.getItem("userInfo")).zhmc
    this.formInline.userName = JSON.parse(sessionStorage.getItem("userInfo")).realname
    // this.$set( this.formInline, "school", JSON.parse(sessionStorage.getItem("userInfo")).zhmc);
    // this.$set(this.formInline, "userName",JSON.parse(sessionStorage.getItem("userInfo")).realname);

    // 性别
    this.radio = JSON.parse(sessionStorage.getItem("userInfo")).xb - 0;
  },
};
</script>
<style lang="scss" scoped>
.information {
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .main_box {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 3.5% 2% 2%;
    box-sizing: border-box;
    position: relative;
    .title {
      font-size: 1.3vw;
      font-weight: bold;
      height: 2vw;
      line-height: 2vw;
    }
    .top_form {
      margin-top: 2vw;
      border-bottom: 2px solid #ededed;
    }
    .Personalization {
      width: 100%;
      height: calc(100% - 16vw);
      // border: 1px solid red;
      box-sizing: border-box;
      padding-top: 2.5%;
      .btm_form {
        margin-top: 2vw;
      }
    }
  }
}
</style>
<style lang="scss">
.information {
  .el-switch__core .el-switch__action {
    width: 10px;
    height: 10px;
    background: #5990ff;
    border: 6px solid #fff;
    box-sizing: content-box;
  }
  .el-switch.is-checked .el-switch__core .el-switch__action {
    left: 88%;
  }
  .el-form--inline .el-form-item {
    width: 40%;
    margin-bottom: 2vw;
    height: 2vw;
  }
  .el-form-item__label {
    font-size: 1vw;
  }
  .el-form--inline .el-form-item__content {
    width: 75%;
    height: 2vw;
  }
  .el-input.is-disabled .el-input__inner {
    box-sizing: border-box;
    padding-left: 2vw;
    color: #333;
    height: 2.55vw;
    font-size: 1vw;
    line-height: 2.55vw;
  }
  .defaultradio {
    .el-radio__inner {
      border: 1px solid #ff7a3f;
    }
    .el-radio__inner::after {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #ff7a3f;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      transition: transform 0.15s ease-in;
    }
  }
  .btm_form {
    .el-form--inline .el-form-item {
      width: 75%;
    }
  }
  .el-switch {
    position: relative;
    height: 24px;
    line-height: 24px;
    &.is-checked {
      .el-switch__core {
        &:after {
          margin-left: -22px;
        }
      }
    }
    .el-switch__label,
    .el-switch__core {
      width: 59px !important;
      height: 24px;
    }
    .el-switch__core {
      border-radius: 12px;
      border: none;
      background: #f1f1f1;
      &:after {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
        width: 22px;
        height: 22px;
      }
    }
    .el-switch__label.is-active {
      color: #333;
    }
    .el-switch__label--right {
      margin-left: 0px;
      &.is-active {
        color: #fff;
        right: 30px;
      }
      position: absolute;
      right: 5px;
      top: -1px;
      color: #fff;
      text-align: right;
      > span {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .el-switch.is-checked {
    .el-switch__core {
      width: 59px;
      border-color: #ececec;
      background-color: #ececec;
    }
  }
  .el-switch.is-disabled {
    opacity: 1;
  }
  .el-switch.is-disabled .el-switch__core,
  .el-switch.is-disabled .el-switch__label {
    cursor: pointer !important;
  }
  .el-radio__label {
    font-size: 1vw;
  }
}
</style>
